.overlayNewProject {
  position: absolute;
    top: 0;
    left: 40%;
    width: 65%;
    height: 100%;
    background: rgba(0, 0, 0, 40%);
    z-index: 2;
    transform: skewX(-11deg);
    overflow: hidden;
}
@media only screen and (max-width: 767px) {
.overlayNewProject {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 40%);
  z-index: 2;
}
}