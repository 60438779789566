.up {
    position: fixed;
    bottom: 10px;
    right: -35px;
    background-color: #FABB25;
    color: white;
    font-weight: bold;
    font-size: 25px;
    padding: 5px;
    border-radius: 4px;
    font-family: Arial, Tahoma;
    cursor: pointer;
    transition: 0.2s;
    width: 35px;
    z-index: 999;
    text-align: center;
}

.up.show {
    right: 10px;
}

.up:hover {
    background: #faba2575;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}