.link {
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
}
.link:hover {
  color: #ce4114;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .link {
    color: #ce4114;
    text-decoration: none;
    transition: 0.5s;
  }
  .link:hover {
    color: #ce4214b6;
    text-decoration: underline;
  }
}

.nav-logo {
  height: 3rem;
  margin: 20px 0;
}
