.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.50);
  z-index: 2;
}
.overlay-footer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.overlay_Definition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 68, 111, 0.50);
  z-index: 2;
  border-radius: 16px;
}

.bg-contact {
            background-image: url('./assets/contacts/laptop-with-many-envelopes.png');
            height: 25rem;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -10%;
}

footer input {
    background-color: rgb(0, 0, 0, 30%);
    width: 100%;
    height: 45px;
    border: none !important;
    outline: none !important;
    padding: 0 35px;
    border-radius: 8px;
    color: #FFF;
    margin: 10px 0;
}

footer input::placeholder {
    color: #FFF;
}

ul {
  margin: 0;
}

ul li::marker {
  color:#FABB25
}
ul li {
  margin: 16px 0;
}