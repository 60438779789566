.header {
  background-image: url("./../../assets/cars/n6.jpeg");
  height: 20rem;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .header {
    background-image: url("./../../assets/cars/n6.jpeg");
    height: 12rem;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
  }

}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
